<!-- 设备管理 -->
<template>
  <div id="device">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <!-- <el-checkbox v-model="allSelect">全选</el-checkbox> -->
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
               :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
               @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt=""/>
        <span>计量点管理</span>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="requestParam">
<!--          <el-form-item label="场站名称">-->
<!--            <el-select filterable v-model="requestParam.stationId" :popper-append-to-body="false" clearable-->
<!--                       placeholder="请选择场站名称">-->
<!--              <el-option v-for="item in stationData" :key="item.id" :label="item.stationName +' '+ item.stationNumber"-->
<!--                         :value="item.id"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="设备名称">-->
<!--            <el-input v-model.trim="requestParam.deviceName" placeholder="请输入设备名称" clearable>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="设备编号">-->
<!--            <el-input v-model.trim="requestParam.deviceNumber" placeholder="请输入设备编号" clearable>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="设备类型">-->
<!--            &lt;!&ndash; <el-input v-model.trim="requestParam.deviceModel" placeholder="请输入设备型号" clearable> &ndash;&gt;-->
<!--            <el-select v-model="requestParam.deviceModel" :popper-append-to-body="false" clearable-->
<!--                       placeholder="请选择设备类型">-->
<!--              <el-option v-for="item in sblxData" :key="item.dictValue" :label="item.dictName"-->
<!--                         :value="item.dictValue"></el-option>-->
<!--            </el-select>-->
<!--            &lt;!&ndash; </el-input> &ndash;&gt;-->
<!--          </el-form-item>-->
<!--          <el-form-item label="安装位置">-->
<!--            <el-select v-model="requestParam.position" :popper-append-to-body="false" clearable-->
<!--                       placeholder="请选择安装位置">-->
<!--              <el-option v-for="item in positionData" :key="item.dictValue" :label="item.dictName"-->
<!--                         :value="item.dictValue"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="用户名称">
            <el-input v-model.trim="requestParam.userName" placeholder="请输入用户名称" @input="handleInput"></el-input>
          </el-form-item>
        </el-form>
        <div class="query-button">
          <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>

          <el-button v-if="isButtonPower('createButtonEqu')" type="primary" icon="el-icon-plus"
                     @click="createDialog">新建计量点
          </el-button>
        </div>
      </div>

      <el-table class="table" :data="tableData" row-key="id" :tree-props="{children: 'childs'}">
        <el-table-column align="center" type="index" label="序号" width="75">
          <template slot-scope="scope">
            <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="userName" label="用户名称">
        </el-table-column>
        <el-table-column align="center" prop="groupName" label="集团名称">
        </el-table-column>
        <el-table-column align="center" prop="voltageLevel" label="电压等级">
          <template slot-scope="scope">
            <span>{{getLabel(scope.row.voltageLevel,e_option)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="proxyUserCategory" label="代购用户分类">
          <template slot-scope="scope">
            <span>{{getLabel(scope.row.proxyUserCategory,g_option)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="electricityFeeType" label="电费类型">
          <template slot-scope="scope">
            <span>{{getLabel(scope.row.electricityFeeType,j_option)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="electricityPriceCategory" label="电价类别">
          <template slot-scope="scope">
            <span>{{getLabel(scope.row.electricityPriceCategory,k_option)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="paymentMethod" label="缴费方式">
          <template slot-scope="scope">
            <span>{{getLabel(scope.row.paymentMethod,n_option)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" prop="operate" width="200px">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
              <div v-if="isButtonPower('editButtonEqu')">
                <span>|</span>
                <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
              </div>
              <div v-if="isButtonPower('deleteButtonEqu')">
                <span>|</span>
                <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page" @click="homePage">首页</button>
        <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
                       layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total"
                       background
                       @size-change="changeSize" @current-change="changeCurrent">
        </el-pagination>
        <button class="tail-page" @click="tailPage">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
                    @change="inputValueChange"></el-input>
          <span class="text">页</span>
          <button class="confirm" @click="confirm">确定</button>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="viewVisible" width="859px" @close="dialogClose('form')">
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" :model="form">
          <el-form-item label="单位">
            <el-cascader class="mycascader" popper-class="cascader-popper" :disabled="true" v-model="+form.companyInfoId"
                         :options="treeData" :props="hierarchyProps" @change="selectedNode"></el-cascader>
          </el-form-item>
          <el-form-item label="用户编号">
            <el-input v-model="form.userId" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计量表编号">
            <el-input v-model="form.deviceNumber" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="集团户号">
            <el-input v-model="form.groupAccountNumber" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="用户名称">
            <el-input v-model="form.userName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="集团名称">
            <el-input v-model="form.groupName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="电压等级">
            <el-select v-model="form.voltageLevel" :popper-append-to-body="false" :disabled="true">
              <el-option v-for="item in e_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户地址">
            <el-input v-model="form.userAddress" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="代购用户分类">
            <el-select v-model="form.proxyUserCategory" :popper-append-to-body="false" :disabled="true">
              <el-option v-for="item in g_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计量点编号">
            <el-input v-model="form.meteringPointId" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="父计量点编号">
            <el-input v-model="form.parentMeteringPointId" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="电费类型">
            <el-select v-model="form.electricityFeeType" :popper-append-to-body="false" :disabled="true">
              <el-option v-for="item in j_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电价类别">
            <el-select v-model="form.electricityPriceCategory" :popper-append-to-body="false" :disabled="true">
              <el-option v-for="item in k_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计量点地址">
            <el-input v-model="form.meteringPointAddress" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="供电服务单位">
            <el-input v-model="form.powerSupplyServiceUnit" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="缴费方式">
            <el-select v-model="form.paymentMethod" :popper-append-to-body="false" :disabled="true">
              <el-option v-for="item in n_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建计量点' : '修改计量点'" :visible.sync="dialogVisible" width="879px"
               @close="dialogClose('form')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="form" :inline="true" :model="form" :rules="rules">
          <el-form-item label="单位" prop="companyInfoId">
            <el-cascader class="mycascader" popper-class="cascader-popper" clearable v-model="+form.companyInfoId"
                         :options="treeData" :props="hierarchyProps" @change="selectedNode"></el-cascader>
          </el-form-item>
          <el-form-item label="用户编号" prop="userId">
            <el-input v-model.trim="form.userId" placeholder="请输入用户编号"></el-input>
          </el-form-item>
          <el-form-item label="计量表编号" prop="deviceNumber">
            <el-input v-model.trim="form.deviceNumber" placeholder="请输入计量表编号" :disabled="disable"></el-input>
          </el-form-item>
          <el-form-item label="集团户号" prop="groupAccountNumber">
            <el-input v-model.trim="form.groupAccountNumber" placeholder="请输入集团户号"></el-input>
          </el-form-item>
          <el-form-item label="用户名称" prop="userName">
            <el-input v-model.trim="form.userName" placeholder="请输入用户名称"></el-input>
          </el-form-item>
          <el-form-item label="集团名称" prop="groupName">
            <el-input v-model.trim="form.groupName" placeholder="请输入集团名称"></el-input>
          </el-form-item>
          <el-form-item label="电压等级" prop="voltageLevel">
            <el-select v-model="form.voltageLevel" :popper-append-to-body="false" clearable
                       placeholder="请选择电压等级">
              <el-option v-for="item in e_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户地址" prop="userAddress">
            <el-input v-model.trim="form.userAddress" placeholder="请输入用户地址"></el-input>
          </el-form-item>
          <el-form-item label="代购用户分类" prop="proxyUserCategory">
            <el-select v-model="form.proxyUserCategory" :popper-append-to-body="false" clearable
                       placeholder="请选择代购用户分类">
              <el-option v-for="item in g_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计量点编号" prop="meteringPointId">
            <el-input v-model.trim="form.meteringPointId" placeholder="请输入计量点编号" :disabled="disable"></el-input>
          </el-form-item>
          <el-form-item label="父计量点编号" prop="parentMeteringPointId">
            <el-input v-model.trim="form.parentMeteringPointId" placeholder="请输入父计量点编号" :disabled="disable"></el-input>
          </el-form-item>
          <el-form-item label="电费类型" prop="electricityFeeType">
            <el-select v-model="form.electricityFeeType" :popper-append-to-body="false" clearable
                       placeholder="请选择电费类型">
              <el-option v-for="item in j_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电价类别" prop="electricityPriceCategory">
            <el-select v-model="form.electricityPriceCategory" :popper-append-to-body="false" clearable
                       placeholder="请选择电价类别">
              <el-option v-for="item in k_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计量点地址" prop="meteringPointAddress">
            <el-input v-model.trim="form.meteringPointAddress" placeholder="请输入计量点地址"></el-input>
          </el-form-item>
          <el-form-item label="供电服务单位" prop="powerSupplyServiceUnit">
            <el-input v-model.trim="form.powerSupplyServiceUnit" placeholder="请输入供电服务单位"></el-input>
          </el-form-item>
          <el-form-item label="缴费方式" prop="paymentMethod">
            <el-select v-model="form.paymentMethod" :popper-append-to-body="false" clearable
                       placeholder="请选择缴费方式">
              <el-option v-for="item in n_option" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="应收年月" prop="paymentMethod">-->
<!--            <el-date-picker v-model="produceMonth" type="month" value-format="yyyy-MM"-->
<!--                            placeholder="月" :clearable="false" popper-class="echarts-picker">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('form')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteFormItem()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {deviceApi} from "@/api/device.js";
import {energyStationApi} from "@/api/energyStation.js";
import {inspectionFormApi} from "@/api/inspectionForm.js";

export default {
  name: "",
  components: {},
  data() {
    return {
      disable: false,
      form: {
        companyInfoId: '',
        userId: '',
        deviceNumber: '',
        groupAccountNumber: '',
        userName: '',
        groupName: '',
        voltageLevel: '',
        userAddress: '',
        proxyUserCategory: '',
        meteringPointId: '',
        parentMeteringPointId: '',
        electricityFeeType: '',
        electricityPriceCategory: '',
        meteringPointAddress: '',
        powerSupplyServiceUnit: '',
        paymentMethod: '',
      },
      //新增窗口单位相关
      hierarchyProps: {
        children: "companyInfoList",
        value: "id",
        label: "companyName",
        checkStrictly:true,
        expandTrigger:'click'
      },
      //电压等级数据源
      e_option: [],
      //代购用户分类
      g_option: [],
      //电费类型
      j_option: [],
      //电价类别
      k_option: [],
      //缴费方式数据源
      n_option: [],
      loading: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        userName: null,
        companyInfoId: null,
      },
      total: 0,
      inputValue: "",
      id: "",
      requestType: 0,
      rules: {
        companyInfoId: [
          {required: true, message: "请选择单位", trigger: "change"},
        ],
        userId: [
          {required: true, message: "请输入用户编号", trigger: "blur"},
        ],
        deviceNumber: [
          {required: true, message: "请输入计量表编号", trigger: "blur"},
        ],

        groupAccountNumber: [
          {required: true, message: "请输入集团户号", trigger: "blur"},
        ],
        userName: [
          {required: true, message: "请输入用户名称", trigger: "blur"},
        ],
        groupName: [
          {required: true, message: "请输入集团名称", trigger: "blur"},
        ],
        voltageLevel: [
          {required: true, message: "请选择电压等级", trigger: "change"},
        ],
        userAddress: [
          {required: true, message: "请输入用户地址", trigger: "blur"},
        ],
        proxyUserCategory: [
          {required: true, message: "请选择代购用户分类", trigger: "change"},
        ],
        meteringPointId: [
          {required: false, message: "请输入计量点编号", trigger: "blur"},
        ],
        parentMeteringPointId: [
          {required: true, message: "请输入父计量点编号", trigger: "blur"},
        ],
        electricityFeeType: [
          {required: true, message: "请选择电费类型", trigger: "change"},
        ],
        electricityPriceCategory: [
          {required: true, message: "请选择电价类别", trigger: "change"},
        ],
        meteringPointAddress: [
          {required: true, message: "请输入计量点地址", trigger: "blur"},
        ],
        powerSupplyServiceUnit: [
          {required: true, message: "请输入供电服务单位", trigger: "blur"},
        ],
        paymentMethod: [
          {required: true, message: "请选择缴费方式", trigger: "change"},
        ],
      },
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      filterText: "",
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      detailsData: {},
      stationData: [],
      positionData: [],
      sblxData: [],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      //选择场站
      dialogStatus: false,
      allButtonPower: [],
    };
  },
  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "计量点管理");
    this.$store.commit("selectChildren", "");
    this.getOptionOne()
    this.getOptionTwo()
    this.getOptionThree()
    this.getOptionFour()
    this.getOptionFive()
  },
  mounted() {
    this.queryUnitName();
    this.queryPosition();
    this.querySblx()
    // this.queryStationName();
    this.allButtonPower = sessionStorage.getItem('roleInfo');
  },

  methods: {
    getLabel(val,option) {
      let name = ''
      option.forEach(item => {
        if (item.dictValue == val){
          name = item.dictName
        }
      })
      return name
    },
    //电压等级
    getOptionOne(){
      let param = {
        dictCode: 'JLB_dydj'
      }
      deviceApi.getOptions(param).then(res => {
        if (res.code == '200'){
          this.e_option = res.data
        }
      })
    },
    //计量表代购用户分类
    getOptionTwo(){
      let param = {
        dictCode: 'JLB_dgyhfl'
      }
      deviceApi.getOptions(param).then(res => {
        if (res.code == '200'){
          this.g_option = res.data
        }
      })
    },
    //计量表电费类型
    getOptionThree(){
      let param = {
        dictCode: 'JLB_dflx'
      }
      deviceApi.getOptions(param).then(res => {
        if (res.code == '200'){
          this.j_option = res.data
        }
      })
    },
    //计量表电价类别
    getOptionFour(){
      let param = {
        dictCode: 'JLB_djlb'
      }
      deviceApi.getOptions(param).then(res => {
        if (res.code == '200'){
          this.k_option = res.data
        }
      })
    },
    //计量表缴费方式
    getOptionFive(){
      let param = {
        dictCode: 'JLB_jffs'
      }
      deviceApi.getOptions(param).then(res => {
        if (res.code == '200'){
          this.n_option = res.data
        }
      })
    },
    handleInput(value) {
      if(!value) {
        this.requestParam.userName = null
      }
    },
    //新增单位相关
    //获取父级最后一集
    selectedNode(val) {
      this.form.companyInfoId = val[val.length - 1];
      console.log(val,'val');
    },
    //设备类型下拉
    querySblx() {
      energyStationApi
          .queryDictionary({dictCode: "deviceModelType"})
          .then((res) => {
            if (res.code === 200) {
              this.sblxData = res.data;
            }
          });
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    // 选择场站
    changeDialog(value) {
      this.dialogStatus = value;
    },
    selectStation() {
      this.dialogStatus = true;
    },
    //-----------------------
    filterNode(value, data) {
      console.log(data,'123123')
      if (!value) return true;
      return data.companyInfoId.indexOf(value) !== -1;
    },
    dialogClose(formName) {
      this.$refs[formName].resetFields();
    },
    createDialog() {
      this.requestType = 0;
      this.disable = false
      this.form.companyInfoId = Number(this.requestParam.companyInfoId);
      this.dialogVisible = true;
    },
    dialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.formAdd();
          } else {
            this.formUpdate();
          }
        } else {
          return false;
        }
      });
    },
    formAdd(){
      deviceApi.addForm(this.form).then(res => {
        if (res.code == '200') {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryDevice();
        }else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    formUpdate(){
      deviceApi.updateForm(this.form).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryDevice();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    deleteFormItem() {
      const param = {
        id:this.id
      }
      deviceApi.deleteItem(param).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryDevice();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },

    updateDialog(data) {
      console.log(data)
      this.requestType = 1;
      this.disable = true
      this.$nextTick(() => {
        Object.assign(this.form, data);
      })
      this.dialogVisible = true;
    },
    viewDialog(data) {
      this.$nextTick(() => {
        Object.assign(this.form, data);
      })
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryDevice();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
            this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryDevice();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryDevice();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryDevice();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryDevice();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryDevice();
    },
    handleCheck(checkedNodes, event) {
      if (this.requestParam.stationId) {
        this.requestParam.stationId = "";
      }
      if (this.requestParam.companyInfoId == checkedNodes.id) {
        this.checkedNodes = ''
        this.requestParam.companyInfoId = null
        event.isCurrent = false
        this.queryDevice();
        this.queryStation(this.checkedNodes.id);
      } else {
        event.isCurrent = true
        this.checkedNodes = checkedNodes;
        this.requestParam.companyInfoId = checkedNodes.id;
        this.queryDevice();
        this.queryStation(this.checkedNodes.id);
      }

    },
    queryDevice() {
      deviceApi.queryMeteringPointManagement(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.tableData.forEach(item => {
            item.companyInfoId = Number(item.companyInfoId);
          })
          this.total = res.total;
        }
      });
    },
    queryStation(companyId) {
      energyStationApi.queryStation({companyId: companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    // 获取安装位置
    queryPosition() {
      energyStationApi
          .queryDictionary({dictCode: "position_type"})
          .then((res) => {
            if (res.code === 200) {
              this.positionData = res.data;
            }
          });
    },
    // 构建
    buildTreeData(data) {
      data.forEach((item, index) => {
        if (item.companyInfoList.length !== 0) {
          this.buildTreeData(item.companyInfoList);
        } else {
          let array = [];
          item.stationInfoList.forEach((element) => {
            if (element !== null) {
              array.push({
                id: element.id,
                companyName: element.stationName,
                final: true,
              });
              item.companyInfoList = array;
            }
          });
        }
      });
      return data;
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          // this.requestParam.companyInfoId = res.data[0].id;
          this.queryDevice();
          this.treeData = res.data;
          this.queryStation();
          // this.queryStation(res.data[0].id);
          // this.$nextTick(() => {
          //   this.$refs.tree.setCurrentKey(this.treeData[0].id); // 默认选中节点第一个
          // });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  computed: {},
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#device {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.main {
  width: calc(100% - 240px);
  background: url("../../assets/images/content-background.png") no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-content {
  margin: 34px 46px 0 60px;
}

/* input */
.dialog-form :deep() .el-date-editor.el-input {
  width: 260px;
}

.dialog-form :deep() .el-input {
  width: 260px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea {
  width: 655px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .special {
  margin-left: 25px;
}

.dialog-form :deep() .block {
  width: 655px;
}

.dialog-form :deep() .block .el-input {
  width: 655px;
}

/* 时间选择器 */
/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

/* 选择场站样式 */
.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.dialog-form:deep() .el-form-item__label {
  width: 98px;
}
</style>
<style>
.cascader-popper {
  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.cascader-popper .el-cascader-menu {
  color: #aed6ff;
}

.cascader-popper .el-cascader-node:hover {
  background: #09224b !important;
}

.cascader-popper .el-cascader-node:focus {
  background: #09224b !important;
}

.cascader-popper .popper__arrow::after {
  border-bottom-color: #071734 !important;
}

.cascader-popper {
  width: 406px;
  overflow: auto;
}

.cascader-popper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

.cascader-popper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
